<template>
  <div class="main-container main-login mt-200 mb-250">
    <div class="container">
      <VLink @click="$router.push({ name: 'showcase' })" class="to_main mt-20">На главную</VLink>
      <form @submit.prevent="onSubmit" class="form form--login form--gap20">
        <div class="block__subtitle">
          Авторизация
        </div>
        <VInput
          name="login"
          id="login"
          v-model="formData.login"
          :errors="v$.login.$errors"
          :server-errors="serverErrors.value?.login"
          @blur="validateField"
          @input="validateField"
          :class-input="['input input-long input-placeholder-black input-border-bottom']"
          placeholder="Логин"
        />
        <VInput
          name="password"
          id="password"
          v-model.trim="formData.password"
          :errors="v$.password.$errors"
          :server-errors="serverErrors.value?.password"
          type="password"
          @blur="validateField"
          @input="validateField"
          :class-input="['input input-long input-placeholder-black input-border-bottom']"
          placeholder="Пароль"
        />

        <div class="form__buttons">
          <button class="btn btn-link mr-30" type="button" @click="toggleIsOpenResetModal">Забыли пароль?</button>
          <router-link :to="{name: 'registration'}">
            <button class="btn btn-link" type="button" >Регистрация</button>
          </router-link>
        </div>

        <div class="form__description">
          Пользуясь сайтом или отправляя любые формы запросов, <br>Вы соглашаетесь c условиями
          <a href="https://sitno.ru/politika-obrabotki-personalnyh-dannyh" target="_blank" class="btn btn-link">«Положения о конфиденциальности»</a>
        </div>

        <VButtonLoading
          :is-disabled="isSend"
          :is-errors="v$.$error"
          color="green"
        >
          Войти
        </VButtonLoading>
      </form>

    </div>
  </div>
  <teleport to="body">
    <VModal
      v-if="isOpenResetModal"
      @close="toggleIsOpenResetModal"
      max-width-class="width-820"
    >
      <h4 class="modal__title mb-40">Восстановить пароль</h4>

      <VInput
        name="login"
        id="login"
        v-model="loginReset.formData.login"
        :errors="loginReset.v$.value.login.$errors"
        :server-errors="loginReset?.serverErrors.value?.login"
        @blur="loginReset.validateField"
        @input="loginReset.validateField"
        class-form="mt-10"
        :class-input="['input input-long input-placeholder-black input-border-bottom']"
        placeholder="Логин*"
      />

      <div class="resetDescription">
        Если вы забыли пароль, введите логин для изменения или востановления пароля.
      </div>

      <div class="modal__buttons">
        <VButtonLoading
          type="button"
          @click="loginReset.onSubmit()"
          :is-disabled="isDisabled"
          :is-errors="loginReset.v$.value.login.$error"
          color="green"
        >
          Отправить
        </VButtonLoading>
      </div>

    </VModal>
  </teleport>
  <TheFooter />
</template>

<script>
import { computed, ref } from 'vue'
import TheFooter from '@/components/TheFooter'
import { useResetForm } from '@/use/Form/Auth/useResetForm'
import { useLoginForm } from '@/use/Form/Auth/useLoginForm'
import VInput from '@/components/ui/form/VInput'
import VModal from '@/components/ui/modal/VModal'
import VLink from '@/components/ui/buttons/VLink'
import VButtonLoading from '@/components/ui/buttons/VButtonLoading'

export default {
  setup () {
    const isOpenResetModal = ref(false)

    const loginReset = useResetForm()

    const toggleIsOpenResetModal = () => {
      if (document.body.classList.contains('no-scroll')) {
        document.body.classList.remove('no-scroll')
      } else {
        document.body.classList.add('no-scroll')
      }
      loginReset.formData.login = ''
      loginReset.v$.value.login.$errors.length = 0
      isOpenResetModal.value = !isOpenResetModal.value
    }

    const isDisabled = computed(() => {
      return loginReset.isSend.value
    })

    return {
      isDisabled,
      ...useLoginForm(),
      toggleIsOpenResetModal,
      isOpenResetModal,
      loginReset
    }
  },
  components: {
    VButtonLoading,
    VModal,
    TheFooter,
    VInput,
    VLink
  }
}
</script>

<style scoped lang="scss">
@import "~@/styles/style.scss";
.resetDescription {
  margin-bottom: 40px;

  max-width: 550px;
  text-align: center;
}
.to_main {
  position: absolute;
  top: $header-auth;
}
</style>
